

main {
    min-height: 80vh;
    background: #fff;
}

.controls-button {
    height: 42px;
}

.audio-controls {
    text-align: center;
    padding: 0.5em .5em;
    background: #333e5a;
    color: #fff;
    display: flex;
    flex-direction: row;
}

.option-radios {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.visualiser-canvas {
    /*padding: 20px;*/
    margin: 0 auto;
    display: block;
}

footer {
    background: #333e5a;
    color: #fff;
    text-align: center;
}